<template>
  <div class="refund_container">
    <!-- 预约成功 -->
    <div class="content">
      <div class="title">
        <van-icon
          name="passed"
          color="rgb(13, 190, 13)"
          size="50px"
        />
        <span>退款成功</span>
      </div>
      <div class="info">
        <van-cell
          title="就诊人"
          :value="orderInfo.name"
          :border="false"
        />
        <van-cell
          title="诊疗卡号"
          :value="orderInfo.medicalCardNo"
          :border="false"
        />
        <van-cell
          title="流水号"
          :value="orderInfo.orderNum"
          :border="false"
        />
        <van-cell
          title="科室"
          :value="orderInfo.deptName"
          :border="false"
        />
        <van-cell
          title="医生"
          :value="orderInfo.doctorName"
          :border="false"
        />
        <van-cell
          title="就诊日期"
          :value="orderInfo.scheduleDate"
          :border="false"
        />
        <van-cell
          title="就诊时间"
          :value="orderInfo.beginTime+'~'+orderInfo.endTime"
          :border="false"
        />
        <van-cell
          title="诊查费"
          :value="orderInfo.regFee+'元'"
          :border="false"
        />
      </div>
      <div class="btn">
        <van-button
          type="info"
          @click="navRegister"
        >返&nbsp;回&nbsp;首&nbsp;页</van-button>
        <van-button
          type="primary"
          @click="navRecord"
        >查&nbsp;看&nbsp;记&nbsp;录</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Refund",
  data() {
    return {
      orderInfo: {}
    };
  },
  created() {
    this.orderInfo = this.$route.query;
  },
  methods: {
    navRegister() {
      this.$router.push({
        path: "/register"
      });
    },
    navRecord() {
      this.$router.push({
        path: "/me/register",
        query: {
          medicalCardNo: this.orderInfo.medicalCardNo
        }
      });
    }
  }
};
</script>

<style scope>
.refund_container .content {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  border: 1px solid #f5f5f5;
  box-shadow: darkgrey 10px 10px 30px 5px;
}
.refund_container .content .title {
  position: relative;
  height: 180px;
  font-size: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 120px;
}
.refund_container .content .info .van-cell__title,
.refund_container .content .info .van-cell__value {
  font-size: 44px;
  line-height: 70px;
}
.refund_container .content .info .van-cell__value {
  flex: 2 !important;
}
.refund_container .content .btn {
  display: flex;
  justify-content: space-around;
  margin: 40px 0;
  padding: 0 40px;
}
.refund_container .content .btn .van-button {
  font-size: 36px;
}
.refund_container .content .btn .refresh {
  width: 100%;
}
</style>